import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-is-a-rad-route"
    }}>{`What is a R.A.D. Route?`}</h1>
    <hr></hr>
    <p>{`R.A.D. Route stands for:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`R.`}</strong>{` Random`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A.`}</strong>{` Access`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`D.`}</strong>{` Data`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Route`}</strong>{` The way R.A.D. is accessed via your/the communities routes. The R.A.D. Routes Runner, which contacts gets your created route from the R.A.D. cloud, and then runs it on your machine.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "radroutescom-cloud"
    }}>{`RADRoutes.com cloud`}</h2>
    <hr></hr>
    <p>{`The R.A.D. Routes Runner, and cloud platform was created to make the sharing, and execution of scripts as simple as possible.`}</p>
    <p><a parentName="p" {...{
        "href": "https://radroutes.com"
      }}>{`R.A.D.Routes.com`}</a>{` is a robust cloud platform that you can create, upload, edit, save, and delete R.A.D. Routes in a selection of coding languages. Share that code with a team, or solo. `}</p>
    <p>{`You can choose to keep your routes private, or have them commented, and voted on by the R.A.D. community`}</p>
    <h2 {...{
      "id": "use-case"
    }}>{`Use Case`}</h2>
    <hr></hr>
    <p>{`R.A.D. Routes is ment to be used mostly for shortish, and normally one file scripts that you want to execute frequently on your computer, server, or team projects.`}</p>
    <h3 {...{
      "id": "some-example-use-cases-are"
    }}>{`Some example use cases are:`}</h3>
    <hr></hr>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Setup custom CI workflows that take optimize of your existing stack`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Orchestrate your containers easily and edit deployments on the fly`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Spin up a Docker, or React starter file consistently, reliably, and securely.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Run frequently used scripts within a team to cut down on copy paste fatigue`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "current-supported-languages"
    }}>{`Current Supported Languages`}</h2>
    <hr></hr>
    <p>{`A R.A.D. Route can currently be written, saved, edited, and executed in `}</p>
    <ul>
      <li parentName="ul">{`Python`}</li>
      <li parentName="ul">{`Node.js`}</li>
      <li parentName="ul">{`Ruby`}</li>
    </ul>
    <p>{`We are hard at work adding more features and functionality; so please let use know what other languages you would be interested in running R.A.D. Routes with.`}</p>
    <h2 {...{
      "id": "version"
    }}>{`Version`}</h2>
    <hr></hr>
    <p>{`R.A.D. Routes is currently in heavy early development. `}</p>
    <p>{`The Current Version is:`}</p>
    <ul>
      <li parentName="ul">{`0.0.1`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      